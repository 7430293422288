<template>
  <div class="exportExcelProject">
    <div class="exportOptionsDialog clearfix">
      <div v-for="(item, index) in getExportOptionsList" :key="index">
        <div class="itemName">{{ item.name }}</div>
        <div class="groupList">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              :label="itemInfo.name"
              v-for="itemInfo in item.fields"
              :key="itemInfo.id"
              style="display: inline-block"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <div class="bottomBtn">
      <div style="float: left; margin-left: 20px">
        <el-button
          type="primary"
          size="mini"
          plain
          @click="checkList = defaultCheckList"
          >searchinglist</el-button
        >
        <el-button type="default" plain size="mini" @click="checkList = []"
          >重置</el-button
        >
      </div>
      <div style="float: right; margin-right: 20px">
        <el-button
          type="default"
          plain
          size="mini"
          @click="
            checkList = [];
            closed();
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="sureExportExcel()"
          >导出</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getExportOptions, exportExcel } from "../api/api";
import * as api from "../api/http";
export default {
  props: ["type", "searchList"],
  data() {
    return {
      getExportOptionsList: [],
      value: null,
      name: "",
      checkList: [],
      defaultCheckList: [
        "最新更新时间",
        "候选人姓名",
        "出生年月",
        "手机号码",
        "邮箱",
        "现居住城市",
        "目前公司",
        "职位",
        "学校名称",
        "学历",
      ],
      saveloading: false,
    };
  },
  created() {
    this.getExportOptions();
  },

  methods: {
    sureExportExcel() {
      if (!this.checkList.length) {
        this.$message.error("请选择导出项");
        return;
      }
      this.saveloading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      exportExcel({
        ...this.searchList,
        fields: this.checkList,
      }).then((res) => {
        this.saveloading = false;
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = api.ApiBaseUrl + res.result;
          }
          this.exportOptionsDialog = false;
          this.checkList = [];
          this.closed();
        }
      });
    },
    getExportOptions() {
      getExportOptions().then((res) => {
        if (res.success) {
          this.getExportOptionsList = res.result;
        }
      });
    },
    closed() {
      this.$emit("showSide");
    },
  },
};
</script>
<style lang="scss" >
.exportExcelProject {
  overflow: hidden;
  height: 100%;
  .el-checkbox__input {
    display: none;
  }
  .el-checkbox__label {
    padding-right: 10px;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(300px);
  opacity: 0;
}
.bottomBtn {
  position: relative;
  bottom: 0;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  line-height: 60px;
  text-align: center;
  box-shadow: 0 -4px 5px -3px #ddd;
}
.exportOptionsDialog {
  position: relative;
  margin: 15px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  height: calc(100% - 90px);
  overflow: auto;
  .itemName {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #606266;
    text-align: left;
    padding: 0 15px;
    margin: 0 0 10px;
    background-color: #f0f0f0;
  }
  .groupList {
    padding: 0 20px;
  }
  .el-checkbox {
    margin-bottom: 10px;
    border: solid 1px #ededed;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    width: 150px;
    margin-right: 15px;

    &.is-checked {
      border: solid 1px #ff3f3f;
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 21px;
        width: 23px;
        // background-image: url("http://192.168.1.111:8979/pics/userHeadImg/d01f9d553ba142ad83433e1c4c2467d7.jpg");
        background-image: url("../assets/choose.png");
        background-size: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}
</style>

