export function composedPath (e) {
    // 存在则直接return
    if (e.path) { return e.path }
    // 不存在则遍历target节点
    let target = e.target
    e.path = []
    while (target.parentNode !== null) {
        e.path.push(target)
        target = target.parentNode
    }
    // 最后补上document和window
    e.path.push(document, window)
   
    return e.path
   
}