<template>
  <section>
    <div class="drawerCon_from" style="border: 0">
      <el-form
        @submit.native.prevent
        :model="from"
        ref="from"
        :rules="rules"
        label-width="130px"
        class="addForm"
      >
        <p style="margin: 10px 20px 20px">请填写以下邮件内容：</p>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item label="职位名称：" prop="position">
              <el-input
                v-model.trim="from.position"
                style="width: 100%"
                @blur="getDetail('position')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="工作城市：" prop="city">
              <el-input
                v-model.trim="from.city"
                style="width: 100%"
                @blur="getDetail('city')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item label="客户公司：" prop="company">
              <el-input
                v-model.trim="from.company"
                style="width: 100%"
                @blur="getDetail('company')"
              ></el-input>
              <p class="tips">可以根据实际需要填写真实公司或某公司等</p>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="薪     资：" prop="salary">
              <el-input
                v-model.trim="from.salary"
                style="width: 100%"
                @blur="getDetail('salary')"
              ></el-input>
              <p class="tips">可以写年薪、月薪或者面议</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="24">
            <el-form-item label="职位描述：">
              <el-input
                v-model="from.jobDescription"
                maxlength="100"
                type="textarea"
                style="width: 90%"
                @blur="getDetail('jobDescription')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="24">
            <el-form-item label="收件人：" class="is-required">
              <el-input
                v-model.trim="from.resume"
                disabled
                :rows="1"
                type="textarea"
                style="width: 90%"
              ></el-input>
              <p class="tips">自动带出勾选对象的邮箱</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="24">
            <el-form-item label="邮件标题：" prop="title">
              <el-input
                v-model.trim="from.title"
                maxlength="100"
                @blur="getDetail('title')"
                style="width: 90%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="邮件预览区：">
              <div v-html="detail" class="htmlContent"></div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button
        type="primary"
        size="mini"
        :loading="saveloading"
        @click="save"
        style="margin-right: 15px"
        >{{ saveloading ? "发送中···" : "发送" }}</el-button
      >
    </div>
  </section>
</template>

<script>
import {
  jobInviteSend, //发送职位邀请邮件
  jobInvitePreview, //预览职位邀请邮件
} from "../../api/api";
export default {
  props: ["mSelection", "projectId", "Info"],
  data() {
    return {
      from: {
        resumeIds: [],
        resume: null,
        title: null,
        position: null,
        city: null,
        company: null,
        salary: null,
        jobDescription: null,
      },
      rules: {
        title: [{ required: true, message: "请输入邮件标题", trigger: "blur" }],
        position: [
          { required: true, message: "请输入职位名称", trigger: "blur" },
        ],
        city: [{ required: true, message: "请输入工作城市", trigger: "blur" }],
        company: [
          { required: true, message: "请输入客户公司", trigger: "blur" },
        ],
        salary: [{ required: true, message: "请输入薪资", trigger: "blur" }],
      },
      detail: null,
      saveloading: false,
    };
  },
  created() {
    let list = [];
    this.mSelection.forEach((i) => {
      list.push(i.email);
      this.from.resumeIds.push(i.resumeId);
    });
    this.from.resume = list.join(" ");
    this.from.position = this.Info.position;
    this.from.city = this.Info.cityName;
    this.from.company = this.Info.companyName;
    this.from.salary = this.Info.monthlySalary
      ? this.Info.monthlySalary + "元/月x" + this.Info.monthCount + "个月"
      : null;
    this.from.jobDescription = this.Info.jd;
  },
  methods: {
    //标题失去焦点获取预览
    getDetail(val) {
      console.log(val);
      if (this.from[val].length == 0) {
        // this.$message.error("请输入标题");
        return false;
      }
      jobInvitePreview({
        city: this.from.city,
        company: this.from.company,
        jobDescription: this.from.jobDescription,
        position: this.from.position,
        salary: this.from.salary,
        projectId: this.projectId,
        resumeIds: this.from.resumeIds,
        title: this.from.title,
      }).then((res) => {
        this.detail = res;
      });
    },
    //发送
    save() {
      return new Promise((resole) => {
        this.$refs["from"].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            jobInviteSend({
              city: this.from.city,
              company: this.from.company,
              jobDescription: this.from.jobDescription,
              position: this.from.position,
              salary: this.from.salary,
              projectId: this.projectId,
              resumeIds: this.from.resumeIds,
              title: this.from.title,
            }).then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.saveloading = false;
                this.$parent.handleClose();
              }
              resole();
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.htmlContent {
  width: 90%;
  min-height: 600px;
  border-radius: 4px;
  border: 1px solid rgb(236, 236, 236);
}
.tips {
  font-size: 12px;
  color: #999;
  line-height: 1;
  margin-top: 3px;
}
</style>
